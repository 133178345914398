<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="10">
        <div class="d-flex pl-2">
          <b-img class="filter-image" :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
            @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'" />
          <div class="search__input w-100 pl-2 pr-0 mr-0">
            <b-input-group>
              <b-form-input v-model="search" placeholder="Cari nama Bank" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </b-col>
      <b-col cols="2" class="px-0">
        <div>
          <b-button variant="primary" @click="$bvModal.show('modal-bank')">
            Tambah Bank
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th v-for="column, index in columns" :key="index">{{ column.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, index in result.data" :key="`table-${index}`">
              <td>{{ item.formatted_id }}</td>
              <td>
                <b-img width="76" height="44" class="border-8 mr-1" style="object-fit:cover;" :src="item.logo_url" />
                {{ item.name || '-' }}
              </td>
              <td>{{ item.description || '-' }}</td>
              <td>
                <div class="btn-group" role="group" aria-label="Basic example">
                  <b-button class="border" size="sm" variant="danger" @click="deleteData(item.uuid)">
                    <feather-icon size="18" class="text-danger" icon="Trash2Icon" style="filter: brightness(5);" />
                  </b-button>
                  <b-button variant="primary" size="sm" @click="editItem(item)">
                    <b-img :src="require('@/assets/images/icons/Process2.svg')" style="filter: brightness(5);" /> Ubah
                  </b-button>
                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>

  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BRow, BCol, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton,
} from 'bootstrap-vue'

// import ModalBank from '@/components/Master/Bank/Modal/ModalBank.vue'

import { VueGoodTable } from 'vue-good-table'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    // ModalBank,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
    BCardCode,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    typeTable: {
      type: String,
    },
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    deleteData: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'formatted_id',
        },
        {
          label: 'Nama Bank',
          field: 'name',
        },
        {
          label: 'Deskripsi Bank',
          field: 'description',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      items: [],
      search: '',
      filter: {
        search: '',
        sort_type: 'desc',
        per_page: 10,
      },
    }
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit('filterBank', value)
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.section__bank {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
