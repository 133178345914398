<template>
  <b-modal id="modal-bank" size="lg" title="Informasi Bank">
    <b-row>
      <b-col cols="12" class="text-center mb-2">
        <UploadPhoto v-if="!photo_url" :label-for="'upload_photo'"
          :style-name="'width:195px;height:140px;margin: 0 auto;'" :photo-url-parent="photo_url" @photo="getPhoto" />
        <div v-else>
          <div class="box__upload--photo mx-auto" :style="`background-image: url(${photo_url});`"
            @click="photo_url = ''">
            <b-avatar class="floating__close--button">
              <feather-icon class="text-danger" icon="XIcon" />
            </b-avatar>
          </div>
        </div>
      </b-col>
      <!-- Nama Gudang -->
      <b-col cols="12" md="12" class="mb-2 custom__form--input">
        <label for="v-nama-gudang">Nama Bank <span class="text-danger">*</span></label>
        <b-form-group>
          <b-form-input id="v-nama-gudang" v-model="formPayload.name" type="text" placeholder="Nama Bank"
            class="custom__input" />
        </b-form-group>
      </b-col>
      <!-- Deskripsi Barang -->
      <b-col cols="12" md="12" class="mb-3 custom__form--input">
        <label for="v-deskripsi">Deskripsi Bank</label>
        <b-form-group>
          <b-form-textarea id="v-deskripsi" v-model="formPayload.description" rows="5" placeholder="Deskripsi Bank"
            class="custom__textarea" />
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer="{}">
      <b-button variant="primary" @click="editUuid == null ? createItem() : updateItem()">
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormTextarea, BAvatar,
} from 'bootstrap-vue'

import UploadPhoto from '@/components/UploadPhoto.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
    UploadPhoto,
    BAvatar,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    iconBank: {
      type: String,
    },
  },
  data() {
    return {
      formPayload: {
        name: '',
        description: '',
        logo: '',
      },
      photo_url: '',
      editUuid: null,
    }
  },
  watch: {
    iconBank(value) {
      this.photo_url = value
    },
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.$emit('getPayload', value)
      },
      deep: true,
    },
  },
  methods: {
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    directPage() {
      this.cleanUpForm()
      this.$bvModal.hide('modal-bank')
    },
  },
}
</script>

<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 195px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
